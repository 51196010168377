
.movepics {
    transform: translateX(0);
    margin-left: 0;
    display: block;
    position: relative;
    width: 81px;
    height: 550px;
  }

  /*ryjgrb*/
  .modalMenu{
    display: flex;
  }
  .modalMenu button{
      margin: 10px;
  }

  
.info {
    margin: 50px auto;
    text-align: center
}

.info a {
    text-decoration: none;
    color: #016993
}

.info a:hover {
    color: #ad8c02
}
.fa {
    color: #fd6969
}
@media only screen and (max-width: 400px) {
    body {
        font-size: 90%
    }
}

@media only screen and (max-width: 800px) {
    body {
        font-size: 100%
    }
}

@media only screen and (min-width: 1100px) {
    body {
        font-size: 120%
    }
}
/*  ===== Выдвигающаяся верхняя панель
*   =========================================
*   Базовый контейнер выдвижной панели */
.top-panel {
    background: #39464e;
    position: absolute;
    top: -250px;
    left: 0;
    width: 100%;
    height: 250px;
    padding: 0;
    border-radius: 0 0 0 45px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    transition: top 400ms cubic-bezier(0.17,0.04,0.03,0.94)
}
/* Блок сообщения */
.message {
    color: #fff;
    font-weight: 300;
    position: relative;
    padding: 3em 2em;
    margin: 0 auto;
    max-width: 980px
}
/* Заголовок 1 уровня */
.message h1 {
    color: #fff
}
/* Заголовок 2 уровня */
.message h2 {
    color: #888
}
.message p {
    margin: 1em 0
}
.message a {
    text-decoration: none;
    color: #748b74
}
.message a:hover {
    color: #95984b
}
/* Переключатель панели */
label.btn {
    display: block;
    position: absolute;
    right: 50px;
    top: 0;
    cursor: pointer;
    background: #2bbbad;
    width: 50px;
    border-radius: 0 0 5px 5px;
    padding: 8px 5px;
    color: #FFF;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    transition: all 400ms cubic-bezier(0.17,0.04,0.03,0.94);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    z-index: 9999;
   
}
/* Переключатель при наведении */
label.btn:hover {
    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15)
}
/* Стрелка переключателя вниз */
label.btn:after {
    content: '\1401';
    font: normal 18px/1 FontAwesome;
    text-decoration: inherit
}
/* Активация панели */
.open:checked ~ .top-panel {
    top: 0
}

.open:checked ~ label.btn {
    top: 250px
}
/* Смещение контейнера контента страницы */
.open:checked ~ .container {
    margin-top: 300px
}
/* Цвет переключателя при клике */
.open:checked + label.btn {
    background: #dd6149
}
/* Стрелка переключателя вверх*/
.open:checked + label.btn:after {
    content: '\1403';
    font: normal 18px/1 FontAwesome
}
/* Контейнер контента */
.container {
    max-width: 980px;
    margin: 0 auto;
    text-align: center;
    transition: margin 400ms cubic-bezier(0.17,0.04,0.03,0.94);
    padding: 5em 3em
}


/*кнопки*/
button:disabled {

    background:rgba(84, 87, 90, 0.18) !important;
}
.button8 {
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: .5em 2em;
    outline: none;
    border-width: 2px 0;
    border-style: solid none;
    border-color: #FDBE33 #000 #D77206;
    border-radius: 6px;
    background: linear-gradient(#F3AE0F, #E38916) #E38916;
    transition: 0.2s;
    height: 103px;
    font-size: 30px;
  } 
  .button8:hover { background: linear-gradient(#f5ae00, #f59500) #f5ae00; }
  .button8:active { background: linear-gradient(#f59500, #f5ae00) #f59500; }