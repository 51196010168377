.App {
   
    
    background-color: rgb(34, 34, 34);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wrapper {
    max-width: 100%;
  
    overflow-x: hidden;
    position: relative;
  }
  @-webkit-keyframes move {
    100% {
      transform: translateX(-100%);
      margin-left: 100%;
    }
  }
  @-moz-keyframes move {
    100% {
      transform: translateX(-100%);
      margin-left: 100%;
    }
  }
  @-o-keyframes move {
    100% {
      transform: translateX(-100%);
      margin-left: 100%;
    }
  }
  @keyframes move {
    100% {
      transform: translateX(-100%);
      margin-left: 100%;
    }
  }
  



 
 
 /*button*/
 